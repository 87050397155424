.min-3 {
  min-height: 75px;
}

.min-4 {
  min-height: 100px;
}

.btn-link {
  color: $btn-link-color;
  text-decoration: none;
  &:visited,
  &:active,
  &:hover {
    color: $btn-link-color;
  }
}

body {
  font-family: $ft-primary;
  color: $light-black;
}

hr {
  opacity: 1;
  border: 0.5px solid $gray-500;
  margin: 1.5rem 0;
}

.btn {
  padding-left: 2rem;
  padding-right: 2rem;
}

.weight-700 {
  font-weight: 700;
}

.flex-box-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.flex-align-end {
  justify-content: flex-end;
}

.details_block_header {
  background-color: $table-defer-title;
}
.navbar {
  &.bg-light {
    background-color: $navbar-bg !important;
  }
  .app_container {
    &.bg-light {
      background-color: $navbar-bg !important;
    }
  }
}

footer {
  &.bg-light {
    background-color: $navbar-bg !important;
  }
  .app_container {
    &.bg-light {
      background-color: $navbar-bg !important;
    }
  }
  a {
    color: $btn-link-color;
    text-decoration: none;
    &:hover,
    &:visited {
      color: $btn-link-color;
      text-decoration: none;
    }
  }
}

.bg-lightblack {
  background-color: $light-black !important;
}

.error_boundary_title {
  text-align: left;
}

.error_boundary_body {
  padding: 20px 0;
  text-align: left;
}

.dotted_line {
  border-bottom: dotted 1px;
}

.app_container {
  min-width: $app-container-min-width;
  max-width: calc(100vw - 10%);
  padding: 0;
  margin: 0 auto;
}

.app_container {
  min-width: $app-container-min-width;
  max-width: calc(100vw - 10%);
  padding: 0;
  margin: 0 auto;
}

.app_container_fullwidth {
  min-width: $app-container-min-width;
  max-width: calc(100vw - 10%);
  padding: 0;
  margin: 0 auto;
}

@media (min-width: 1280px) and (max-width: 1920px) {
  .app_container {
    min-width: 100%;
  }
  .app_container_fullwidth {
    min-width: 100%;
  }
}

.overflow-auto {
  overflow: auto;
}

.sorticon {
  position: relative;
  height: 9px;
  display: inline-block;
  margin-left: 6px;
  text-align: center;

  .arrow {
    border: solid $light-black;
    border-width: 0 1px 1px 0;
    display: inline-block;
    position: absolute;
    height: 5px;
    width: 5px;
    left: 0px;
    &.up {
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
      top: 0;
    }

    &.down {
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      bottom: 0;
    }
  }
}

.asc {
  .arrow.up {
    border-width: 0 2px 2px 0;
    left: -0.5px;
    width: 6px;
    height: 6px;
  }
}

.desc {
  .arrow.down {
    border-width: 0 2px 2px 0;
    bottom: -1px;
    left: -0.5px;
    width: 6px;
    height: 6px;
  }
}
.download-btn {
  display: flex;
  flex: 0.05;
  justify-content: flex-end;
  margin-top: 30px !important;
  span {
    color: $body-color;
    padding-left: 4px;
    padding-top: 3px;
    font-weight: 700;
  }
  &.dropdown-toggle::after {
    margin-top: 10px;
    color: $body-color;
  }
}

.download-icon {
  background-image: url("../assets/download_icon.png");
  height: 1.5rem;
  width: 1.5rem;
  display: inline-block;
}

.phone-icon {
  background-image: url("../assets/phone.png");
  height: 1.5rem;
  width: 1.5rem;
  display: inline-block;
}

.email-icon {
  background-image: url("../assets/email.png");
  height: 1.5rem;
  width: 1.5rem;
  display: inline-block;
}

.transient_screen .tb_summery_card,
.transienttaxes_screen .tt_summery_card {
  padding-left: 0;
  padding-right: 0;
}

.contactus {
  .icon_pos {
    position: relative;
    top: 8px;
    margin-right: 8px;
  }
  li {
    list-style-type: none;
  }
  .bg {
    background-color: $table-header-color;
  }
}

.bold {
  font-weight: 800;
}

.user_text_overflow {
  text-transform: uppercase;
  text-overflow: ellipsis;
  max-width: 250px;
  overflow: hidden;
  display: block;
}

.app {
  float: left;
  width: 100%;
}

.deferpayment_info_text {
  color: $gray-600;
  font-style: italic;
}

.broadcast_msg_block {
  width: 800px;
}

.inside_block_edit_reason {
  &.form-item select {
    padding: 0 4px;
    height: 22px;
  }
  .save_all_stay {
    font-size: 0.75rem;
    margin: 0 4px;
    padding-top: 0;
    padding-bottom: 0;
  }
}
.nopay-dropdown {
  letter-spacing: 0.5px;
}

.overlay-collection {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
  .overlay_info-bar-comp {
    margin-bottom: 10px;
    position: relative;
  }
  .overlay_info-bar-comp .alert-bar {
    position: static;
  }
}
