.card{
     background:$body-bg;
    box-shadow: 0px .12rem .43rem .12rem rgba(0, 0, 0, 0.1);
    border-radius:0.75rem;
}

.card-link{

     color: $orange;
    text-decoration:none;
}

.icon-regular{
    width: 1.4rem;
    height: 1.4rem;
}

.icon-medium{
    width: 1.9rem;
    height: 1.1rem;
}

.icon-large{
    width: 2.4rem;
    height: 1.4rem;
}

.icon-l{
    width: 26px;
    height: 24px;
}
.min-ht-140{
    min-height: 8.75rem;
}

.font-20{
    font-size: 1.25rem;
}

.padding-left{
    padding-left: 1rem;
}



