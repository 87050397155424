.property_title_bar {
  .input-container {
    position: relative;
  }
  h1 {
    margin-top: 0.5rem;
  }

  .input-field {
    padding-right: 25px;
  }
  select {
    width: 200px;
  }
  .input-field:focus-visible {
    outline: 0;
  }
  .icon-property {
    background-image: url("../../assets/right-arrow.png");
    position: absolute;
    top: 7px;
    right: 20px;
  }
  .error-border {
    border: 2px solid $red;
  }
  .error-message {
    color: $red;
    font-size: 0.8rem;
  }
  .hidden {
    visibility: hidden;
    color: $red;
    font-size: 0.8rem;
  }
}
