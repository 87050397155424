.stay-summary-comp {
  .icon-cls {
    width: 0.6rem;
    height: 0.9rem;
  }
}

.save_all_stay {
  float: right;
}

.form-item-summary.tax_included {
  position: absolute;
  left: 40.5%;
  top: 14.9rem;
  div {
    float: left;
  }
  label {
    float: left;
    padding-right: 5px;
  }
  #field-item-taxIncluded {
    position: relative;
    top: -6px;
    left: -3px;
  }
}

.add-stay-enter-details {
  .step-2-stay {
    position: relative;
  }
  #field-item-guestName,
  #field-item-bookingSource {
    text-transform: uppercase;
  }
}

.void_stay-no-shadow {
  opacity: 0 !important ;
}
