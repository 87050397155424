.info-bar-comp {
  .alert-bar {
    color: $body-color;
    background-color: $table-header-color;
  }
  .success-infobar {
    border: 1px solid $green;
  }

  .danger-infobar {
    border: 1px solid $red;
  }

  .dark-infobar {
    color: $white;
    background-color: $orange;
  }

  .img-class {
    height: 1.5rem;
    width: 1.5rem;
  }
  .support {
    position: relative;
  }
  .alert-info {
    color: $body-color;
    background-color: $info;
  }
  .alert-dismissible .btn-close {
    padding: 12px;
  }
}
