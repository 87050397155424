@import "../../style/variable.scss";

.overlay_info-bar-comp {
  .alert-bar {
    color: $body-color;
    background-color: $table-header-color;
    position: fixed;
    //width: 300px;
    //min-width: 200px;
    margin: 0 auto;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    display: flex;
    // margin: 0;
    // top: 50%;
    // left: 50%;
    // -ms-transform: translate(-50%, -50%);
    // transform: translate(-50%, -50%);
  }
  .success-infobar {
    border: 2px solid $green;
  }

  .danger-infobar {
    border: 2px solid $red;
  }

  .dark-infobar {
    color: $white;
    background-color: $orange;
  }

  .img-class {
    height: 1.5rem;
    width: 1.5rem;
  }
  .support {
    position: relative;
  }
  .alert-info {
    color: $body-color;
    background-color: $info;
  }
  .infobar_text {
    font-size: 1rem;
  }
  .alert-dismissible .btn-close {
    padding: 12px;
  }
}
