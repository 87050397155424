.form-item {
  &.disabled {
    opacity: 0.4;
  }
  label {
    color: $gray-600;
    font-size: 0.85rem;
  }
  .form-sub-item {
    margin: 4px;
    label {
      color: $light-black;
      font-size: 1rem;
    }
  }
  input[type="checkbox"] {
    // margin: 4px;
    // -webkit-appearance: none;
    // appearance: none;
  }
  input[type="radio"] {
    color: $light-black;
    padding: 4px;
  }

  input[type="text"],
  input[type="number"],
  input[type="date"] {
    border: 1px solid $gray-400;
    color: $gray-600;
    padding: 4px;
    height: 31px;
    border-radius: 4px;
    &::placeholder {
      opacity: 0.7;
    }
  }

  .readonly_text {
    font-size: 0.75rem;
  }

  select[multiple] {
    height: auto;
  }

  select {
    border: 1px solid $gray-400;
    color: $gray-600;
    padding: 4px;
    height: 31px;
    border-radius: 4px;
  }
  select:focus-visible,
  input[type="text"]:focus-visible,
  input[type="number"]:focus-visible {
    outline: 0;
  }
}

.filter-form {
  input[type="text"],
  input[type="number"],
  .checkbox .form-sub-item,
  select,
  .multi-select {
    width: calc(100% - 25px) !important;
  }

  .date-icon {
    left: calc(100% - 55px);
    right: 0;
  }

  .flex-60 {
    flex: 1 1 60% !important;
  }

  .radio .form-sub-item {
    width: 100% !important;
  }

  .form-item {
    flex: 1;
  }

  .clear-all-btn {
    text-decoration: none;
    padding-right: 20px;
  }
  .apply-btn {
    border-radius: 2rem;
  }
  .apply-btn-validate {
    border-radius: 2rem;
    padding: 0px 50px;
  }
  .apply-btn-update {
    border-radius: 2rem;
    font-weight: 700;
  }
}

input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  //background-color: #fff;
  margin: 0;
  color: $light-black;
  width: 1.15em;
  height: 1.15em;
  border: 1px solid $light-black;
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: inline-grid;
  place-content: center;

  &::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em $light-black;
    border-radius: 50%;
  }

  &:checked::before {
    transform: scale(1);
  }

  &:disabled {
    border: 1px solid $gray-500;
    cursor: not-allowed;
    &::before {
      box-shadow: inset 1em 1em $gray-500;
    }
  }
}

input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  //background-color: #fff;
  margin: 0;
  color: $light-black;
  width: 1.15em;
  height: 1.15em;
  border: 1px solid $light-black;
  border-radius: 0.15em;
  display: inline-grid;
  place-content: center;

  &:indeterminate::before {
    content: "";
    width: 0.65em;
    height: 0.15em;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em $light-black;
    transform: scale(1);
  }

  &::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em $light-black;
  }

  &:checked::before {
    transform: scale(1);
  }

  &:disabled {
    border: 1px solid $gray-500;
    cursor: not-allowed;
    &::before {
      box-shadow: inset 1em 1em $gray-500;
    }
  }
}
.transient-tax-filters {
  .form-item {
    flex: 1 1 23%;
    #field-item-guestName {
      text-transform: uppercase;
    }
  }
}
.transient-summary-filters {
  .form-item {
    flex: 1 1 16%;
    #field-item-guestName {
      width: 93% !important;
      text-transform: uppercase;
    }
    #field-item-mod {
      width: 50% !important;
    }
    &.invoice_filter {
      input,
      .form-sub-item {
        margin-left: 0;
        margin-top: 0;
      }
    }
  }
}

.audit-filters {
  position: relative;
  .form-item {
    flex: 1 1 15%;
  }
  .blank_iata {
    position: absolute;
    top: -5px;
    left: 5.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row-reverse;

    label {
      margin-top: 3px;
      font-size: 0.75rem;
    }
    input[type="checkbox"] {
      font-size: 0.75rem;
    }
  }
}

.step-1-stay {
  .form-item {
    margin-right: 35px;
  }
}

.step-2-stay {
  position: relative;
  .apply-btn-update {
    position: absolute;
    left: 505px;
    top: 18px;
  }
}

.col-3-form .form-item {
  flex: 0 0 33%;
  margin-bottom: 20px;
  select,
  input[type="text"],
  input[type="number"] {
    width: 85%;
  }
}

.col-3-form .form-item-summary {
  margin-bottom: 30px;
}

.form-item-summary {
  position: relative;
  label {
    &.required::after {
      color: #e32;
      content: " *";
      display: inline;
    }
  }
  input[type="text"],
  input[type="number"] {
    border: 1px solid $gray-400;
    padding: 4px;
    height: 31px;
    border-radius: 4px;
    font-size: 1.1rem;
  }

  select[multiple] {
    height: auto;
  }

  select {
    border: 1px solid $gray-400;
    color: $gray-600;
    padding: 4px;
    height: 31px;
    border-radius: 4px;
  }
  select:focus-visible,
  input[type="text"]:focus-visible,
  input[type="number"]:focus-visible {
    outline: 0;
  }
  .form-error {
    font-size: 0.65rem;
    width: 100%;
  }
}
.group-block-filters {
  .form-item {
    flex: 1 1 20%;
  }
}
