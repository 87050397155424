.currency_comp {
  .non_active {
    background-color: $navbar-bg;
    color: $primary;
  }

  .btn {
    border-radius: 0.06rem;
    border: none;
    min-width: 6rem;
  }

  .disabled {
    color: $disabled-button-bg;
    background-color: $body-bg;
    border: 0.06rem solid $disabled-button-bg;
  }
}
