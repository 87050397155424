.data-table-server {
  table {
    width: 100%;
    &.fixed-width-table {
      table-layout: fixed;
      width: fit-content;
      .text-style {
        white-space: wrap;
      }
    }
  }
  thead {
    position: sticky;
    top: 0;
    z-index: 1;
  }
  th {
    padding: 3px 5px;
    background-color: $table-header-color;
    .save_all_stay {
      margin-left: 155px;
      padding-right: 0;
    }
  }
  .save_all_stay {
    padding-right: 0;
    float: left;
    margin-left: -2px;
  }
  td {
    padding: 3px 5px;
  }
  tr {
    border-bottom: 0.5px solid $table-border;
  }
  .action-button th {
    background-color: $body-bg;
  }
  .text-style {
    font-size: 0.75rem;
    font-weight: 700;
    display: flex;
    align-items: center;
    white-space: nowrap;
  }
  .table-body-text {
    font-size: 0.75rem;
    font-weight: 400;
    word-break: break-word;
  }
  .accordian {
    display: inline-block;
    height: 15px;
    width: 9px;
    margin-top: 1rem;
  }
  .arrow-up {
    background-image: url("../../assets/arrow-up.png");
  }
  .arrow-down {
    background-image: url("../../assets/arrow-down.png");
  }
  .action_btn {
    font-size: 0.75rem;
    padding-left: 18px;
    padding-right: 10px;
    display: inline-block;
    text-decoration: none;
    font-weight: 700;
  }
  .add_stay {
    margin-left: 35px;
    min-width: 100px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .header_right_align {
    float: right;
    padding-right: 0;
    text-align: right;
  }
  .header_center_align {
    float: none;
    padding-right: 0;
    text-align: center;
    justify-content: center;
  }
  .sorting_enabled {
    .align-right {
      padding-right: 22px;
    }
  }
  .pay-history {
    tr:last-child {
      border-bottom: none;
    }
  }
}
