.loading-bar-container {
  position: absolute;
  background: rgba(255, 255, 255, 0.85);
  height: 100%;
  width: 100%;
  &.global {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    left: 0;
    z-index: 1;
  }
  .loading-bar {
    width: 44px;
    height: 44px;
    position: absolute;
    top: calc(50% - 22px);
    left: 0;
    right: 0;
    margin: auto;
  }
}
