.group-batch-table {
  position: relative;
}

.download-menu {
  //margin-top: 28px;
}

.group-block-filters {
  position: relative;
  .show_cancel_blocks {
    position: absolute;
    left: 0;
    bottom: -10px;
    flex: none;
    div {
      float: left;
    }
    label {
      float: right;
      padding-top: 9px;
      font-size: 0.75rem;
    }
    input[type="checkbox"] {
      font-size: 0.75rem;
    }
  }
}

.cascading_select_box {
  width: 175px;
}
