.multi-select {
  width: 80%;
  .btn-select {
    color: #707070;
    border: 1px solid #d2d2d2;
    padding: 3px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:visited,
    &:active,
    &:hover {
      border: 1px solid #d2d2d2;
      color: #707070;
    }
  }
  .dropdown-menu {
    background-color: white;
    border: 1px solid #d2d2d2;
    padding: 0;
    margin: 0;
    max-height: 170px;
    overflow-y: scroll;
    min-width: fit-content;
    width: 100%;
  }
  .form-check {
    display: flex;
    align-items: center;
  }
  .count-circle {
    height: 25px;
    width: 25px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    color: black;
    vertical-align: middle;
    margin-left: 6px;
    &.blue {
      background-color: #0052cc;
      color: white;
      .count {
        color: white;
      }
    }
  }
  .count {
    color: black;
    font-weight: bold;
  }
  .drop-box {
    display: flex;
    padding: 5px 15px;
    white-space: nowrap;
    align-items: center;
  }
}
