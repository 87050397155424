// Your variable overrides
$red: #d0021b;
$white: #fff;
$black: #000;
$orange: #b84c16;
$gray-300: #e6e6e6;
$gray-400: #d2d2d2;
$gray-500: #c4c4c4;
$gray-600: #707070;
$green: #477d00;
$light-orange: #dc6f50;
$light-yellow: #ffdaa0;
$light-blue: #cad7eb;

$light-black: #1c1c1c;
$body-bg: #fff;
$body-color: #1c1c1c;
$table-header-color: #f5f5f5;
$table-defer-title: #f4f4f4;
$navbar-light-bg: $gray-400;
$navbar-bg: $gray-300;
$border-color: $gray-500;
$light-grey: $gray-600;
$table-border: #c5c5c5;
$primary: $light-black;
$btn-link-color: $orange;
$dark: $orange;
$info: #d9e8f6;
$pop-bg: #e4e4e4;
$border-radius-btn: 3rem;
$disabled-button-bg: $light-grey;
$ft-primary: "swis721_bt";
$no-suggestion: #999;
$suggestion-bg: #1e90ff;
$suggestion-border: #f1ecec;

$status-red-bg: #ffe3e1;
$status-red-text: #b52217;
$status-red-border: #b52217;

$status-orange-bg: #ffeb9c;
$status-orange-text: #9c5700;
$status-orange-border: #9c5700;

$container-max-widths: (
  sm: 1280px,
  md: 1280px,
  lg: 1280px,
  xl: 1280px,
  xxl: 1320px,
) !default;
// scss-docs-e

$app-container-min-width: 1280px;

//@include alert-success($green, $green, $green);
