.curved-corner {
  border-radius: $border-radius-btn;
}

.ft-primary {
  font-family: "swis721_bt", sans-serif;
}

.font-30 {
  font-size: 1.87rem;
}

.font-16 {
  font-size: 1rem;
}

.font-20 {
  font-size: 1.25rem;
}

.font-22 {
  font-size: 1.37rem;
}

.font-12 {
  font-size: 0.75rem;
}

.marginHorizontal {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.marginTitle {
  margin-top: 3rem;
  margin-bottom: 1.25rem;
}

.paddingTransient {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.9rem;
}

.paragraph-wrap {
  overflow: hidden;
  white-space: nowrap;
}
.transient-tax {
  display: table;
}
.transient-tax-value {
  overflow-wrap: anywhere;
  width: 100%;
}
.vh-body {
  min-height: calc(100vh - 250px);
}

.curved-box {
  border-radius: 0.75rem;
  box-shadow: rgb(0 0 19 / 37%) 0px 3px 8px;
  display: table-cell;
}

i {
  height: 1rem;
  width: 1rem;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
}

.popover {
  background-color: $pop-bg;
  .popover-arrow {
    display: none;
  }
}
.pop-over {
  div {
    padding: 5px 5px 5px 5px;
  }
}

.view-stays {
  text-decoration: none;
  color: $orange;
  font-size: 0.75rem;
}

.view-stays-link {
  text-decoration: none;
  color: $orange;
}

.table-button {
  padding: 0px 8px;
  margin-right: 0.75rem;
  border: none;
  color: $light-black;
  text-align: left;
  width: 80px;
  border-radius: 3px;
  background-color: transparent;
}

.gra_table .table-button {
  width: auto;
}

.status-red {
  background-color: $status-red-bg;
  color: $status-red-text;
  border: 1px solid $status-red-border;
  font-weight: 600;
}
.status-orange {
  background-color: $status-orange-bg;
  color: $status-orange-text;
  border: 1px solid $status-orange-border;
  font-weight: 600;
}
.no-style {
  padding: 0;
}

.select-dropdown {
  color: #707070;
  width: 150px;
  height: 35px;
  margin-top: 5px;
}

.property-amount {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 1.5rem;
}

.property-amount-summary {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 2.2rem;
}

.align-right {
  float: right;
  padding-right: 1px;
}

.pagination-info {
  flex: 1;
  align-self: flex-end;
}

.error-border .date-picker .no-border {
  border: 0 !important;
}

.date-picker {
  position: relative;
  .form-control {
    padding: 0.2rem 0.5rem;
    text-transform: uppercase;
  }

  input[type="text"]::after {
    content: "";
    position: absolute;
    right: 5px;
    width: 28px;
    height: 28px !important;
  }

  .date-icon {
    background: url("../assets/calender_icon.png") center/contain no-repeat;
    width: 20px;
    height: 20px !important;
    position: absolute;
    bottom: 5px;
    right: 5px;
  }

  .date-hidden {
    width: 0 !important;
    height: 0 !important;
    position: absolute;
    bottom: 0px;
  }

  .form-control:focus {
    box-shadow: none;
  }
}
.error-border {
  input:not([type="checkbox"]),
  select,
  .dropdown-toggle {
    border: 1px solid $red !important;
  }
}
.tax-overview {
  flex-grow: 1;
  display: flex;
}
.tax-card {
  width: 100%;
}
.text-wrap-next-line {
  overflow-wrap: anywhere;
}

.text-center {
  text-align: center;
}
.open-status-bg {
  background-color: $green;
  color: $body-bg;
}
.inactive-status-bg,
.grey-status-bg {
  background-color: $light-grey;
  color: $body-bg;
  margin-left: -8px;
}
.pending-status-bg {
  background-color: $light-yellow;
}
.released-status-bg {
  background-color: $light-blue;
}

.error_color {
  color: $red;
}

.alert_color {
  background: $red;
  color: $white;
  padding: 10px;
}

.success_color {
  color: $green;
}

.form-error {
  position: absolute;
  @extend .error_color;
}

.cursor-pointer {
  cursor: pointer;
}

.add-stay,
.edit-stay {
  margin: 0 0.5rem;
  padding: 0.5rem 0rem;
}
.pay-history {
  padding: 10px 50px 0px 50px;
}
.uppercase {
  text-transform: uppercase;
}

.orange-bar {
  background-color: $light-orange;
  color: $table-header-color;
  padding: 0.25rem;
  position: sticky;
  top: 0;
  z-index: 3;
}

.btn-dark {
  &:hover,
  &:active {
    color: #fff;
  }
}

.btn-secondary {
  background-color: $white;
  color: $light-black;
  &:hover,
  &:active {
    color: $white;
    background-color: $light-black;
  }
}
.form-item select:disabled,
.select-dropdown:disabled {
  opacity: 0.5;
}

.clear-left {
  clear: left;
}

.disable_opcity {
  opacity: 0.2;
  i {
    cursor: default;
  }
}

.returnto {
  .icon-cls {
    width: 0.6rem;
    height: 0.9rem;
    margin-top: 4px !important;
  }
}

.blank_option {
  background-color: $gray-500;
}

.inside_block_table,
.outside_block_table {
  table {
    width: auto !important;
  }
}

.modify-form-stay {
  &.review-mode .form-item {
    margin-bottom: 8px;
  }
  .date-picker .form-control,
  .form-item .readonly_text {
    font-size: 1.1rem;
    text-transform: uppercase;
  }
  input[type="text"],
  select {
    width: 100%;
  }
  .form-item {
    margin-right: 15px;
    margin-bottom: 20px;
  }
  .separator {
    margin: 15px 0 15px 0;
  }
}

.separator {
  width: 100%;
}

.adv_com_amt {
  margin-right: -14px !important;
}

.showform {
  height: auto !important;
  overflow: auto;
}

.hideform {
  height: 0 !important;
  overflow: hidden;
}

.no-border {
  border: 0 !important;
}

.negative_margin {
  margin-top: -57px;
}
.negative_margin_review {
  margin-top: -20px;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.hide {
  display: none;
}

.flex-none-275 {
  flex: none !important;
  width: 300px;
}

.flex-none {
  flex: none !important;
}

.stay-overlay .modal-content {
  padding: 10px 15px;
}

.footer_section,
.app_header .app_container {
  background-color: $gray-300;
}

.disabled_link {
  opacity: 0.5;
  cursor: not-allowed;
}
