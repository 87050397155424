@import "../../../../style/variable.scss";

.add-adjustment {
  margin: 10px 20px 20px;
  .modify-form-stay {
    .form-item {
      margin-right: 50px;
    }
  }
}

.form-component .flex-full-width.form-item {
  flex: 0 0 100%;
}

.adjustcode-div,
.nopayreason-div {
  width: 250px;
}

.add-adjustment-overlay.overlay-fixed-min-width {
  min-width: 860px;
}
