.action-bar-comp{
    .action-bar-alert{
        background-color: $light-orange;
        color: $table-header-color;
        padding: 0.25rem;
    }
    .action-btn{
        color: $table-header-color;
        border-radius: 1.8rem;
        margin:0.25rem 2rem;
        padding: 0.25rem 0.63rem;
        border: 0.06rem solid $table-header-color;
    }
    .action-btn:hover{
        background-color: transparent;
    }
    .action-bar-info{
        padding-top: 0.25rem;
    }

    .action-cancel-btn{
        color: $table-header-color;
        margin-top: 0.25px;
    }
        .action-cancel-btn:hover{
        background-color: transparent;
    }
}
