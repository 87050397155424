@font-face {
  font-family: "swis721_bt";
  src: url("./font/swis721_bt/Swiss721BT.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "swis721_bt";
  src: url("./font/swis721_bt/Swiss721ItalicBT.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "swis721_bt";
  src: url("./font/swis721_bt/Swiss721BoldBT.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "swis721_bt";
  src: url("./font/swis721_bt/Swiss721BoldItalicBT.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica";
  src: url("./font/Helvetica/Helvetica.ttf") format("truetype");
}
