.tab-navigation {
  border-bottom: 1px solid $border-color;
}
.nav-tabs {
  .nav-item {
    margin-right: 2rem;
  }
  .nav-link,
  .nav-link:hover {
    border-top: none;
    border-left: none;
    border-right: none;
    font-weight: normal;
    border-bottom: 1px solid transparent;
  }
  .nav-link.active {
    border-bottom: solid 3px $light-black;
    font-weight: bold;
  }
}
