.broadcast-container {
  border: 1px solid $border-color;
  border-radius: 0.18rem;
}
.broadcast-outer {
  padding: 0.9rem;
  display: flex;
  flex: 1;
  a {
    color: $orange;
  }
  .alert-icon-img {
    flex: 0.025;
    .alert-icon {
      background-image: url("../../assets/alert.png");
      height: 1.5rem;
      width: 1.25rem;
      display: inline-block;
    }
  }
  .alert-msg {
    flex: 0.875;
    p {
      padding: 0;
      margin: 0;
    }
  }
  .close {
    flex: 0.1;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    cursor: pointer;
    .close-icon {
      height: 0.94rem;
      width: 0.94rem;
      background-image: url("../../assets/close.png");
    }
  }
}
