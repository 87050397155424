.data-table {
  th {
    padding: 0.35rem;
    background-color: $table-header-color;
  }
  td {
    padding: 0.5rem 0.5rem 0.8rem;
  }
  tr {
    border-bottom: 0.5px solid $table-border;
  }
  .edit-icon {
    background-image: url("../../assets/edit.png");
    display: inline-block;
  }
  .text-color {
    color: $orange;
    font-size: 0.75rem;
    font-weight: 700;
    text-decoration: none;
    cursor: pointer;
  }
}
