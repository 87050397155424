.navbar-brand a {
  color: $white;
  text-decoration: none;
}

.landing-logo-text {
  padding: 0.5rem;
  display: inline-block;
  color: $white;
  text-decoration: none;
}
.nav-link {
  color: $light-black;
  font-weight: 700;
  display: flex;
  align-items: center;
  i {
    margin: 0px 0.3rem;
  }
  span {
    font-size: 1rem;
  }
  .commission-icon {
    background-image: url("../../assets/commission.png");
  }
  .contact-icon {
    background-image: url("../../assets/contact.png");
  }
  .alert-icon {
    background-image: url("../../assets/alert.png");
    width: 0.94rem;
    height: 0.94rem;
  }
}
#basic-nav-dropdown::after {
  display: none !important;
}
.bg-dark {
  background-color: $navbar-light-bg !important;
}
.app_header {
  .dropdown-menu {
    background-color: $navbar-light-bg;
    border-radius: 0;
    border: none;
    top: 36px !important;
    width: 100%;
    .menu-item {
      padding: 0.2rem 0rem 0.3rem 2.2rem;
      width: 100%;
      display: inline-block;
      text-decoration: none;
      color: $light-black;
    }
    .active {
      font-weight: 700;
    }
    .dropdown-item:active {
      background-color: transparent;
    }
  }
}
.user-profile {
  margin-left: 0.5rem;
  padding: 0.2rem;
  .logout {
    padding-left: 2.3rem;
  }
  .profile-icon {
    background-image: url("../../assets/profile.png");
    width: 0.94rem;
  }
}
